<template>
  <div class="">
    <div class="flex justify-between items-center mb-6">
      <div>
        <h1 class="text-4xl">Manage Float</h1>
        <!-- <p class="text-sm text-gray-400">Welcome to your dashboard</p> -->
      </div>

      <div class="flex">
        <router-link
          to="/buffer_management"
          class="
            border
            px-6
            py-2
            rounded-lg
            text-sm
            mr-2
            flex
            justify-center
            items-center
            hover:bg-gray-100
          "
        >
          Buffer Management
        </router-link>
        <router-link
          to="/replenishment"
          class="
            border
            px-6
            py-2
            rounded-lg
            text-sm
            flex
            justify-center
            items-center
            hover:bg-gray-100
          "
        >
          Float Replenishment
        </router-link>
      </div>
    </div>

    <div class="flex flex-col">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div
            class="
              shadow
              overflow-hidden
              border-b border-gray-200
              sm:rounded-lg
            "
          >
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
                  >
                    #ID
                  </th>
                  <th
                    scope="col"
                    class="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
                  >
                    Bank
                  </th>
                  <th
                    scope="col"
                    class="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
                  >
                    Date
                  </th>
                  <th
                    scope="col"
                    class="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
                  >
                    Total
                  </th>

                  <th
                    scope="col"
                    class="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
                  >
                    Replenished By
                  </th>

                  <th
                    scope="col"
                    class="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
                  >
                    Created At
                  </th>

                  <th scope="col" class="relative px-6 py-3">
                    <span class="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody v-if="floats" class="bg-white divide-y divide-gray-200">
                <tr v-for="(float, i) in floats.data" :key="i">
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">#{{ float.id }}</div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="text-sm font-medium text-gray-900">
                      {{ float.bank_name }}
                    </div>
                    <div class="text-sm text-gray-500">
                      SN:{{ float.slip_number }}
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    {{ float.date }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    GMD {{ formatPrice(total(float.allocations)) }}
                  </td>
                  <td class="px-6 text-sm py-4 whitespace-nowrap">
                    {{ float.created_by }}
                  </td>
                  <td class="px-6 text-sm py-4 whitespace-nowrap">
                    {{
                      float.created_at | moment("ddd, MMM Do YYYY, h:mm:ss a")
                    }}
                  </td>

                  <td
                    class="
                      px-6
                      py-4
                      whitespace-nowrap
                      text-right text-sm
                      font-medium
                    "
                  >
                    <router-link :to="{ path: 'manage_float/' + float.id }">
                      <span class="text-indigo-600 hover:text-indigo-900">
                        View</span
                      >
                    </router-link>
                  </td>
                </tr>

                <!-- More people... -->
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
// import BranchSales from "../../components/Charts/BranchSales";

import { mapGetters } from "vuex";
import checkPermissionExist from "../../mixins/checkPermissionExist";

export default {
  middleware: "auth",
  layout: "default",
  components: {
    // BranchSales,
    // HelloWorld
  },

  mixins: [checkPermissionExist],
  computed: mapGetters({
    user: "auth/user",
  }),

  data: () => ({
    floats: null,
  }),

  created() {
    var _this = this;
    _this.loadData(1);
    if (!this.checkPermissionExist(this.user.data, "floats-read")) {
      this.$router.go(-1);
      return false;
    }
  },

  methods: {
    total(param) {
      return param.reduce(function (total, item) {
        return total + item.balance_allocated;
      }, 0);
    },

    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    async loadData(pageNo, filter) {
      // Submit the form
      if (filter) {
        // filter = filter;
        this.searching = true;
      } else {
        filter = "";
        this.searching = false;
      }

      try {
        const { data } = await axios.get(
          `/obtain_floats?page=${pageNo}&filter=${filter}`
        );

        console.log(data);

        this.floats = data;
        this.searching = false;

        // console.log("The current page is: ", this.agents.meta.current_page);

        return true;
      } catch (e) {
        console.log("Error");
        this.searching = false;
        return false;
      }
    },
  },
};
</script>
